import React, { useState, useEffect } from "react";
import { useForm } from 'react-hook-form';
import * as mutations from "../graphql/mutations"
import { updateMutation } from "../composable/useMutation"
import {v4 as uuidv4} from "uuid";
type InputContact = { nickname: string; email: string; title:  string; content:string; };

const id = uuidv4();

export const Contact: React.VFC = () => {
	const [isClicked, setIsClicked] = useState(false);
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	const createReceptionNumber = () => {
		let tempAlphabet = "";
		let tempNumber = "";
		const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
		const number = "0123456789";
		for (let i = 0; i < 4; i++) {
			tempAlphabet += alphabet.charAt(
				Math.floor(Math.random() * alphabet.length)
			);
			tempNumber += number.charAt(Math.floor(Math.random() * number.length));
		}
		return tempAlphabet + tempNumber;
	}
	const [ isCheck, setIsCheck] = useState(false);
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();
	const onsubmit = async (data: InputContact) => {
		if(!isClicked) {
			setIsClicked(true);
			const inputContact = {
				nickname: data.nickname,
				email: data.email,
				title: JSON.stringify({ question: data.title }),
				content: JSON.stringify({ question: data.content }),
			};
			const receptionNumber = createReceptionNumber();
			const contactData = {
				id: id,
				...inputContact,
				receptionNumber: receptionNumber,
				status: 0,
				contactCategoryId: "",
			}
			const result = await updateMutation(mutations.createContact, contactData);
			if (result) {
				window.location.reload();
			}
		}
	};
	const handleChange = () => {
		setIsCheck(!isCheck);
	}
	return(
		<div className="mx-auto">
			<div className="w-[100%] h-[250px] m-auto text-center bg-cover bg-center bg-[url('./assets/vdaon07-1-1024x683.jpeg')]">
				<div className="flex h-[250px] bg-black opacity-70">
					<span className="m-auto text-white font-bold text-2xl sm:text-3xl md:text-4xl lg:text-5xl">お問い合わせ</span>
				</div>
			</div>
			<form className="container m-auto" onSubmit={handleSubmit(onsubmit)}>
			<div className="mt-5 md:mt-10 w-[90%] md:w-[75%] m-auto text-start bg-gray-100 text-gray-500 p-5 md:p-10">
				<div className="relative">
					<div className="after:content-['*'] after:text-red-500">お名前</div>
					<div className="mb-10">
						<input type="text" className="border form-input w-[300px] md:w-[350]" {...register('nickname', { required: '名前を入力してください。' })}></input>
						<p className="text-red-500">{errors.nickname?.message}</p>
					</div>
					<div className="after:content-['*'] after:text-red-500">メールアドレス</div>
				<div className="mb-10">
					<input type="text"  className="border form-input w-[300px] md:w-[350]" {...register('email', { required: 'メールアドレスを入力してください。' , pattern: {
							value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
							message: '有効なメールアドレスを入力してください。例）example@example.co.jp / example@example.com',
						}, })}></input>
					<p className="text-red-500">{errors.email?.message}</p>
				</div>
				<div>題名</div>
				<div className="mb-10">
					<input type="text" className="border form-input w-[300px] md:w-[350]" {...register('title')}></input>
				</div>
				<div>メッセージ本文</div>
				<div className="mb-10">
					<textarea className="form-input px-4 w-[300px] md:w-[500px] h-[300px]" {...register('content')}></textarea></div>

					<div className="inline-block mt-2">
						<span>お問い合わせをお送りしますか？</span>
						<span className="after:content-['*必須'] after:text-red-500"></span>
					</div>
					<div className="inline-block ml-5">
						<label>
							<input type="checkbox" onChange={handleChange}></input>
						</label>
					</div>
				<div className="mt-10"><button disabled={isCheck === false && isClicked === false} className={"bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded" + (isCheck ? 'cursor-pointer': 'cursor-not-allowed')}>送信</button></div>
			</div>
			</div>
			</form>
		</div>
	)
}