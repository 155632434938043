import React, {useEffect} from "react";
export const Company: React.VFC = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return(
			<div className="m-auto">
				<div className="w-[100%] h-[250px] m-auto text-center bg-cover bg-center bg-[url('./assets/vdaon01-2-1024x683.jpeg')]">
					<div className="flex h-[250px] bg-black opacity-70">
						<span className="m-auto text-white font-bold text-2xl sm:text-3xl md:text-4xl lg:text-5xl">会社概要</span>
					</div>
				</div>
				<div className="text-gray-500 text-sm md:text-md lg:text-lg xl:text:xl   mt-10 m-auto w-[75%]">
					<div className="text-start p-8 bg-gray-100 mb-8"><strong>会社名称: </strong>株式会社ｄａＯｎ</div>
					<div className="text-start p-8 bg-gray-100 mb-8"><strong>設立: </strong>2014年5月15日 </div>
					<div className="text-start p-8 bg-gray-100 mb-8"><strong>代表者: </strong>黄　淳光</div>
					<div className="text-start p-8 bg-gray-100 mb-8"><strong>資本金: </strong>1000万円</div>
					<div className="text-start p-8 bg-gray-100 mb-8"><strong>所在地: </strong>東京都新宿区大久保２－７－１</div>
				</div>
			</div>
	)
}