import React from "react";
import Header from "./components/header/Header";
import Sidebar from "./components/header/Sidebar";
import Menu from "./components/header/Menu";
import Footer from "./components/footer/Footer";
import TopButton from "./components/topButton/TopButton"
import { Home } from "./page/Home";
import { Company } from "./page/Company";
import { Philosophy } from "./page/Philosophy";
import { Detail } from "./page/Detail";
import { Performance } from "./page/Performance";
import { Recruitment } from "./page/Recruitment";
import { Contact } from "./page/Contact";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css'
import { Amplify } from "aws-amplify";
import awsExports from "./aws-exports";

Amplify.configure(awsExports);

export const App : React.VFC = () => {
	const [isOpen, setIsOpen] = React.useState(false);
	return (
		<div className="App">
		<BrowserRouter>
			<Header setIsOpen={setIsOpen}></Header>
			<Sidebar isOpen={isOpen} setIsOpen={setIsOpen}>
				<Menu></Menu>
			</Sidebar>
			<Routes>
				<Route index element={<Home />} />
				<Route path="company" element={<Company />} />
				<Route path="philosophy" element={<Philosophy />} />
				<Route path="detail" element={<Detail />} />
				<Route path="performance" element={<Performance />} />
				<Route path="recruitment" element={<Recruitment />} />
				<Route path="contact" element={<Contact />} />
			</Routes>
			<TopButton></TopButton>
			<Footer></Footer>
		</BrowserRouter>
		</div>
	);
}

export default App;
