/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createAdmin = /* GraphQL */ `
  mutation CreateAdmin(
    $input: CreateAdminInput!
    $condition: ModelAdminConditionInput
  ) {
    createAdmin(input: $input, condition: $condition) {
      id
      isAdmin
      adminName
      email
      phoneNumber
      ttl
      createdAt
      updatedAt
    }
  }
`;
export const updateAdmin = /* GraphQL */ `
  mutation UpdateAdmin(
    $input: UpdateAdminInput!
    $condition: ModelAdminConditionInput
  ) {
    updateAdmin(input: $input, condition: $condition) {
      id
      isAdmin
      adminName
      email
      phoneNumber
      ttl
      createdAt
      updatedAt
    }
  }
`;
export const deleteAdmin = /* GraphQL */ `
  mutation DeleteAdmin(
    $input: DeleteAdminInput!
    $condition: ModelAdminConditionInput
  ) {
    deleteAdmin(input: $input, condition: $condition) {
      id
      isAdmin
      adminName
      email
      phoneNumber
      ttl
      createdAt
      updatedAt
    }
  }
`;
export const createCategoryMaster = /* GraphQL */ `
  mutation CreateCategoryMaster(
    $input: CreateCategoryMasterInput!
    $condition: ModelCategoryMasterConditionInput
  ) {
    createCategoryMaster(input: $input, condition: $condition) {
      id
      typeName
      category
      createdAt
      updatedAt
    }
  }
`;
export const updateCategoryMaster = /* GraphQL */ `
  mutation UpdateCategoryMaster(
    $input: UpdateCategoryMasterInput!
    $condition: ModelCategoryMasterConditionInput
  ) {
    updateCategoryMaster(input: $input, condition: $condition) {
      id
      typeName
      category
      createdAt
      updatedAt
    }
  }
`;
export const deleteCategoryMaster = /* GraphQL */ `
  mutation DeleteCategoryMaster(
    $input: DeleteCategoryMasterInput!
    $condition: ModelCategoryMasterConditionInput
  ) {
    deleteCategoryMaster(input: $input, condition: $condition) {
      id
      typeName
      category
      createdAt
      updatedAt
    }
  }
`;
export const createContact = /* GraphQL */ `
  mutation CreateContact(
    $input: CreateContactInput!
    $condition: ModelContactConditionInput
  ) {
    createContact(input: $input, condition: $condition) {
      id
      category {
        id
        typeName
        category
        createdAt
        updatedAt
      }
      receptionNumber
      nickname
      email
      title
      content
      imageUrl
      status
      createdAt
      updatedAt
      contactCategoryId
    }
  }
`;
export const updateContact = /* GraphQL */ `
  mutation UpdateContact(
    $input: UpdateContactInput!
    $condition: ModelContactConditionInput
  ) {
    updateContact(input: $input, condition: $condition) {
      id
      category {
        id
        typeName
        category
        createdAt
        updatedAt
      }
      receptionNumber
      nickname
      email
      title
      content
      imageUrl
      status
      createdAt
      updatedAt
      contactCategoryId
    }
  }
`;
export const deleteContact = /* GraphQL */ `
  mutation DeleteContact(
    $input: DeleteContactInput!
    $condition: ModelContactConditionInput
  ) {
    deleteContact(input: $input, condition: $condition) {
      id
      category {
        id
        typeName
        category
        createdAt
        updatedAt
      }
      receptionNumber
      nickname
      email
      title
      content
      imageUrl
      status
      createdAt
      updatedAt
      contactCategoryId
    }
  }
`;
export const createFaq = /* GraphQL */ `
  mutation CreateFaq(
    $input: CreateFaqInput!
    $condition: ModelFaqConditionInput
  ) {
    createFaq(input: $input, condition: $condition) {
      id
      category {
        id
        typeName
        category
        createdAt
        updatedAt
      }
      title
      content
      imageUrl
      isView
      createdAt
      updatedAt
      faqCategoryId
    }
  }
`;
export const updateFaq = /* GraphQL */ `
  mutation UpdateFaq(
    $input: UpdateFaqInput!
    $condition: ModelFaqConditionInput
  ) {
    updateFaq(input: $input, condition: $condition) {
      id
      category {
        id
        typeName
        category
        createdAt
        updatedAt
      }
      title
      content
      imageUrl
      isView
      createdAt
      updatedAt
      faqCategoryId
    }
  }
`;
export const deleteFaq = /* GraphQL */ `
  mutation DeleteFaq(
    $input: DeleteFaqInput!
    $condition: ModelFaqConditionInput
  ) {
    deleteFaq(input: $input, condition: $condition) {
      id
      category {
        id
        typeName
        category
        createdAt
        updatedAt
      }
      title
      content
      imageUrl
      isView
      createdAt
      updatedAt
      faqCategoryId
    }
  }
`;
export const createNotice = /* GraphQL */ `
  mutation CreateNotice(
    $input: CreateNoticeInput!
    $condition: ModelNoticeConditionInput
  ) {
    createNotice(input: $input, condition: $condition) {
      id
      title
      category {
        id
        typeName
        category
        createdAt
        updatedAt
      }
      content
      imageUrl
      isView
      startTime
      endTime
      viewCount
      createdAt
      updatedAt
      noticeCategoryId
    }
  }
`;
export const updateNotice = /* GraphQL */ `
  mutation UpdateNotice(
    $input: UpdateNoticeInput!
    $condition: ModelNoticeConditionInput
  ) {
    updateNotice(input: $input, condition: $condition) {
      id
      title
      category {
        id
        typeName
        category
        createdAt
        updatedAt
      }
      content
      imageUrl
      isView
      startTime
      endTime
      viewCount
      createdAt
      updatedAt
      noticeCategoryId
    }
  }
`;
export const deleteNotice = /* GraphQL */ `
  mutation DeleteNotice(
    $input: DeleteNoticeInput!
    $condition: ModelNoticeConditionInput
  ) {
    deleteNotice(input: $input, condition: $condition) {
      id
      title
      category {
        id
        typeName
        category
        createdAt
        updatedAt
      }
      content
      imageUrl
      isView
      startTime
      endTime
      viewCount
      createdAt
      updatedAt
      noticeCategoryId
    }
  }
`;
export const createProcess = /* GraphQL */ `
  mutation CreateProcess(
    $input: CreateProcessInput!
    $condition: ModelProcessConditionInput
  ) {
    createProcess(input: $input, condition: $condition) {
      id
      type
      content
      createdAt
      updatedAt
    }
  }
`;
export const updateProcess = /* GraphQL */ `
  mutation UpdateProcess(
    $input: UpdateProcessInput!
    $condition: ModelProcessConditionInput
  ) {
    updateProcess(input: $input, condition: $condition) {
      id
      type
      content
      createdAt
      updatedAt
    }
  }
`;
export const deleteProcess = /* GraphQL */ `
  mutation DeleteProcess(
    $input: DeleteProcessInput!
    $condition: ModelProcessConditionInput
  ) {
    deleteProcess(input: $input, condition: $condition) {
      id
      type
      content
      createdAt
      updatedAt
    }
  }
`;
export const createEventBanner = /* GraphQL */ `
  mutation CreateEventBanner(
    $input: CreateEventBannerInput!
    $condition: ModelEventBannerConditionInput
  ) {
    createEventBanner(input: $input, condition: $condition) {
      id
      title
      pageUrl
      imageUrl
      isView
      startTime
      endTime
      createdAt
      updatedAt
    }
  }
`;
export const updateEventBanner = /* GraphQL */ `
  mutation UpdateEventBanner(
    $input: UpdateEventBannerInput!
    $condition: ModelEventBannerConditionInput
  ) {
    updateEventBanner(input: $input, condition: $condition) {
      id
      title
      pageUrl
      imageUrl
      isView
      startTime
      endTime
      createdAt
      updatedAt
    }
  }
`;
export const deleteEventBanner = /* GraphQL */ `
  mutation DeleteEventBanner(
    $input: DeleteEventBannerInput!
    $condition: ModelEventBannerConditionInput
  ) {
    deleteEventBanner(input: $input, condition: $condition) {
      id
      title
      pageUrl
      imageUrl
      isView
      startTime
      endTime
      createdAt
      updatedAt
    }
  }
`;
