import logo from "../../assets/logo.png";
import { Link } from "react-router-dom";
export default function header ({setIsOpen}: any) {

  return (
    <header className="top-0 w-full bg-white justify-center">
      <nav
        className="mx-auto flex max-w-7xl items-center justify-between lg:px-8 z-20"
        aria-label="Global"
      >
        <div className="flex lg:flex-1">
          <a href="/" className="m-1.5 p-1.5">
            <img className="h-12 w-auto" src={logo} alt="" />
          </a>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700 mr-3"
            onClick={() => setIsOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
              />
            </svg>
          </button>
        </div>
        <div className="lg:flex lg:gap-x-12 hidden">
          <Link to="/" className="text-sm font-semibold leading-6 text-gray-500 hover:scale-125 hover:underline">
            ホーム
          </Link>
          <Link to="/company" className="text-sm font-semibold leading-6 text-gray-500 hover:scale-125 hover:underline">
            会社概要
          </Link>
          <Link to="/philosophy" className="text-sm font-semibold leading-6 text-gray-500 hover:scale-125 hover:underline">
            経営理念
          </Link>
          <Link to="/detail" className="text-sm font-semibold leading-6 text-gray-500 hover:scale-125 hover:underline">
            事業内容
          </Link>
          <Link to="/performance" className="text-sm font-semibold leading-6 text-gray-500 hover:scale-125 hover:underline">
            業務実績
          </Link>
          <Link to="/recruitment" className="text-sm font-semibold leading-6 text-gray-500 hover:scale-125 hover:underline">
            採用情報
          </Link>
          <Link to="/contact" className="text-sm font-semibold leading-6 text-gray-500 hover:scale-125 hover:underline">
            お問い合わせ
          </Link>
        </div>
      </nav>
    </header>
  );
};

