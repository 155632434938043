import React, {useEffect} from "react";
export const Detail: React.VFC = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return(
		<div className="mx-auto">
			<div className="w-[100%] h-[250px] m-auto text-center bg-cover bg-center bg-[url('./assets/vdaon03-7-1024x683.jpeg')]">
				<div className="flex h-[250px] bg-black opacity-70">
					<span className="m-auto text-white font-bold text-2xl sm:text-3xl md:text-4xl lg:text-5xl">事業内容</span>
				</div>
			</div>
			<div className="text-gray-500 text-xl mt-10 m-auto w-[75%]">
				<div className="italic text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold p-10 mb-32">
					システムコンサルティング
				</div>
				<hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
				<div className="italic text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold p-10 mb-32">
					アプリケーション開発
				</div>
				<hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
				<div className="italic text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold p-10 mb-32">
					システム運用補修
				</div>
				<hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
				<div className="italic text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold p-10 mb-32">
					インプラ構築
				</div>
				<hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
				<div className="italic text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold p-10">
					ファンクラブサイト構築＆運営
				</div>
			</div>
		</div>
	)
}