import React from "react";
import { Link } from "react-router-dom"
import menuImage1 from "../assets/vdaon01-2-1024x683.jpeg";
import menuImage2 from "../assets/vdaon04-2-1024x683.jpeg";
import menuImage3 from "../assets/vdaon03-7-1024x683.jpeg";
import menuImage4 from "../assets/vdaon05-1-1024x683.jpeg";
import menuImage5 from "../assets/vdaon06-1-1024x683.jpeg";
import menuImage6 from "../assets/vdaon07-1-1024x683.jpeg";
export const Home: React.VFC = () => {
  return(
    <>
     <div className="h-[200px] sm:h-[300px] md:h-[400px] lg:h-[500px] bg-cover bg-center opacity-70 bg-[url('./assets/main.jpeg')] bg-fixed ">
       <div className="flex h-[200px] sm:h-[300px] md:h-[400px] lg:h-[500px] ">
       <div className="w-[75%] sm:w-[50%] lg:w-[40%] xl:w-[25%] m-auto">
        <span className="text-black sm:text-xl md:text-3xl lg:text-4xl m-auto font-bold">競争力ある技術、<br/>
          優れた技術、<br/>
          社会に貢献 、<br/>
          社会から信頼される企業を目指してまいります。</span>
       </div>
       </div>
    </div>
    <div className="container w-[75%] mt-10 m-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 z-10">
      <Link to="/company" className="relative text-sm sm:text-sm md:text-md lg:text-lg font-semibold leading-6 text-gray-700 hover:duration-500">
        <img src={menuImage1} alt="" className="m-auto hover:opacity-75"/><div className="absolute bottom-0 w-[100%] bg-gray-100 bg-opacity-70 p-4 hover:underline">会社概要</div>
      </Link>
      <Link to="/philosophy" className="relative text-sm sm:text-sm md:text-md lg:text-lg font-semibold leading-6 text-gray-700 hover:duration-500">
        <img src={menuImage2} alt="" className="m-auto hover:opacity-75"/><div className="absolute bottom-0 w-[100%] bg-gray-100 bg-opacity-70 p-4 hover:underline">経営理念</div>
      </Link>
      <Link to="/detail" className="relative text-sm sm:text-sm md:text-md lg:text-lg font-semibold leading-6 text-gray-700 hover:duration-500">
        <img src={menuImage3} alt="" className="m-auto hover:opacity-75"/><div className="absolute bottom-0 w-[100%] bg-gray-100 bg-opacity-70 p-4 hover:underline">事業内容</div>
      </Link>
      <Link to="/performance" className="relative text-sm sm:text-sm md:text-md lg:text-lg font-semibold leading-6 text-gray-700 hover:duration-500">
        <img src={menuImage4} alt="" className="m-auto hover:opacity-75"/><div className="absolute bottom-0 w-[100%] bg-gray-100 bg-opacity-70 p-4 hover:underline">業務実績</div>
      </Link>
      <Link to="/recruitment" className="relative text-sm sm:text-sm md:text-md lg:text-lg font-semibold leading-6 text-gray-700 hover:duration-500">
        <img src={menuImage5} alt="" className="m-auto hover:opacity-75"/><div className="absolute bottom-0 w-[100%] bg-gray-100 bg-opacity-70 p-4 hover:underline">採用情報</div>
      </Link>
      <Link to="/contact" className="relative text-sm sm:text-sm md:text-md lg:text-lg font-semibold leading-6 text-gray-700 hover:duration-500">
        <img src={menuImage6} alt="" className="m-auto hover:opacity-75"/><div className="absolute bottom-0 w-[100%] bg-gray-100 bg-opacity-70 p-4 hover:underline">お問い合わせ</div>
      </Link>
    </div>
    </>
  )
}