import { Link } from "react-router-dom";
export default function Menu({ setIsOpen }:any) {
	return(
		<>
			<div className="p-4 mx-auto">
				<Link to="/" onClick={() => setIsOpen(false)} className="text-sm font-semibold leading-6 text-gray-900 hover:text-indigo-700">
					ホーム
				</Link>
			</div>
			<div className="p-4 mx-auto"><Link to="/company" onClick={() => setIsOpen(false)} className="text-sm font-semibold leading-6 text-gray-900 hover:text-indigo-700">
				会社概要
			</Link>
				</div>
			<div className="p-4 mx-auto"><Link to="philosophy" onClick={() => setIsOpen(false)} className="text-sm font-semibold leading-6 text-gray-900 hover:text-indigo-700">
				経営理念
			</Link>
			</div>
			<div className="p-4 mx-auto"><Link to="detail" onClick={() => setIsOpen(false)} className="text-sm font-semibold leading-6 text-gray-900 hover:text-indigo-700">
				事業内容
			</Link>
				</div>
			<div className="p-4 mx-auto"><Link to="performance" onClick={() => setIsOpen(false)} className="text-sm font-semibold leading-6 text-gray-900 hover:text-indigo-700">
				業務実績
			</Link>
			</div>
			<div className="p-4 mx-auto"><Link to="recruitment" onClick={() => setIsOpen(false)} className="text-sm font-semibold leading-6 text-gray-900 hover:text-indigo-700">
				採用情報
			</Link>
				</div>
			<div className="p-4 mx-auto "><Link to="contact" onClick={() => setIsOpen(false)} className="text-sm font-semibold leading-6 text-gray-900  hover:text-indigo-700">
				お問い合わせ
			</Link></div>
		</>
	)
}