import React from "react";
import logo from "../../assets/logo.png"
import {Link} from "react-router-dom"

export default function Footer() {
  return(
    <footer className="p-4 bg-white sm:p-6 dark:bg-gray-900">
      <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
      <div className="md:flex items-center">
        <div className="mb-6 md:mb-0">
          <a href="/" className="flex items-center">
            <img src={logo} className="h-12 ml-12 mt-4 mr-3" alt="Logo" />
          </a>
        </div>
        <div className="font-semibold m-auto grid grid-cols-2 lg:grid-cols-6">
            <Link to="/company" className="mr-5 text-gray-500 hover:underline">会社概要</Link>
            <Link to="/philosophy" className="mr-5 text-gray-500 hover:underline">経営理念</Link>
            <Link to="/detail" className="mr-5 text-gray-500 hover:underline">事業内容</Link>
            <Link to="/performance" className="mr-5 text-gray-500 hover:underline">業務実績</Link>
            <Link to="/recruitment" className="mr-5 text-gray-500 hover:underline">採用情報</Link>
            <Link to="/contact" className="mr-5 text-gray-500 hover:underline">お問い合わせ</Link>
        </div>
      </div>
      <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
      <div className="sm:flex sm:items-center sm:justify-between">
        <div className="flex mt-4 font-semibold text-gray-500 space-x-6 sm:justify-center sm:mt-0 text-start">
          株式会社ｄａＯｎ<br/>
          〒169-0072　東京都新宿区大久保2丁目7番1号 大久保フジビル7階<br/>
          TEL: 03-5291-5800<br/>
          FAX: 03-5291-5801<br/>
        </div>
        <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2023 株式会社ｄａＯｎ All Rights Reserved.
        </span>
      </div>
    </footer>
  )
}