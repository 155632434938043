import React, {useEffect} from "react";
export const Recruitment: React.VFC = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return(
		<div className="mx-auto">
			<div className="w-[100%] h-[250px] m-auto text-center bg-cover bg-center bg-[url('./assets/vdaon06-1-1024x683.jpeg')]">
				<div className="flex h-[250px] bg-black opacity-70">
					<span className="m-auto text-white font-bold text-2xl sm:text-3xl md:text-4xl lg:text-5xl">採用情報</span>
				</div>
			</div>
			<div className="text-gray-500 text-xl mt-10 m-auto w-[75%]">
				<div className="text-start p-8 bg-gray-100 mb-8"><strong>職種：</strong>ソフトウェア開発</div>
				<div className="text-start p-8 bg-gray-100 mb-8"><strong>雇用形態：</strong>正社員、契約社員 </div>
				<div className="text-start p-8 bg-gray-100 mb-8"><strong>勤務地所在地：</strong>本社または都内</div>
				<div className="text-start p-8 bg-gray-100 mb-8"><strong>勤務時間：</strong>9：00～18：00(実働8時間、休憩60分)</div>
				<div className="text-start p-8 bg-gray-100 mb-8"><strong>休日休暇：</strong>土・日、祝日、夏季、年末年始、慶弔休暇等 </div>
				<div className="text-start p-8 bg-gray-100 mb-8"><strong>社会保険：</strong>厚生年金、医療保険、労働保険等 </div>
			</div>
		</div>
	)
}