import React, {useEffect} from "react";
export const Performance: React.VFC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return(
    <div className="mx-auto">
      <div className="w-[100%] h-[250px] m-auto text-center bg-cover bg-center bg-[url('./assets/vdaon05-1-1024x683.jpeg')]">
        <div className="flex h-[250px] bg-black opacity-70">
          <span className="m-auto text-white font-bold text-2xl sm:text-3xl md:text-4xl lg:text-5xl">業務実績</span>
        </div>
      </div>
      <div className="text-gray-500 text-xl mt-10 m-auto w-[75%]">
        <div className="text-gray-700 text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold p-10">
          通信
        </div>
        <div className="italic text-md sm:text-1xl md:text-2xl lg:text-3xl font-bold p-10 mb-32">
          <ol className="list-disc list-inside">
            <li>3G VoIP向け基地局システム</li>
            <li>4G VoLTE向け基地局システム</li>
            <li>デジタルテレビ映像転送システム</li>
          </ol>
        </div>
        <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
        <div className="text-gray-700 text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold p-10">
          官庁
        </div>
        <div className="italic text-md sm:text-1xl md:text-2xl lg:text-3xl font-bold p-10 mb-32">
          <ol className="list-disc list-inside">
            <li>社会保険システム</li>
            <li>収納システム</li>
            <li>税証明システム</li>
            <li>特許庁システム</li>
            <li>地方自治体福祉システムほか</li>
          </ol>
        </div>
        <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
        <div className="text-gray-700 text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold p-10">
          他
        </div>
        <div className="italic text-md sm:text-1xl md:text-2xl lg:text-3xl font-bold p-10 mb-32">
          <ol className="list-disc list-inside">
            <li>小売販売配送・特注システム</li>
            <li>証券会社向け営業支援システム</li>
            <li>金融勘定系システム</li>
            <li>法人インターネットバンキング</li>
            <li>ファンクラブサイト構築</li>
          </ol>
        </div>
      </div>
    </div>
  )
}